@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proximanova/ProximaNova-Light.eot');
    src: local('Proxima Nova Light'), local('ProximaNova-Light'),
        url('../fonts/proximanova/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proximanova/ProximaNova-Light.woff2') format('woff2'),
        url('../fonts/proximanova/ProximaNova-Light.woff') format('woff'),
        url('../fonts/proximanova/ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proximanova/ProximaNova-Regular.eot');
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url('../fonts/proximanova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proximanova/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/proximanova/ProximaNova-Regular.woff') format('woff'),
        url('../fonts/proximanova/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proximanova/ProximaNova-Semibold.eot');
    src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
        url('../fonts/proximanova/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proximanova/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/proximanova/ProximaNova-Semibold.woff') format('woff'),
        url('../fonts/proximanova/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proximanova/ProximaNova-Bold.eot');
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url('../fonts/proximanova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proximanova/ProximaNova-Bold.woff2') format('woff2'),
        url('../fonts/proximanova/ProximaNova-Bold.woff') format('woff'),
        url('../fonts/proximanova/ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-Regular.eot');
    src: local('MinionPro-Regular'),
        url('../fonts/minionpro/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-Regular.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-Regular.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-It.eot');
    src: local('MinionPro-It'),
        url('../fonts/minionpro/MinionPro-It.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-It.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-It.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-Medium.eot');
    src: local('MinionPro-Medium'),
        url('../fonts/minionpro/MinionPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-Medium.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-Medium.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-MediumIt.eot');
    src: local('MinionPro-MediumIt'),
        url('../fonts/minionpro/MinionPro-MediumIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-MediumIt.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-MediumIt.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-Semibold.eot');
    src: local('MinionPro-Semibold'),
        url('../fonts/minionpro/MinionPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-Semibold.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-Semibold.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Minion Pro SmBd';
    src: url('../fonts/minionpro/MinionPro-SemiboldIt.eot');
    src: local('MinionPro-SemiboldIt'),
        url('../fonts/minionpro/MinionPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-SemiboldIt.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-SemiboldIt.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-Bold.eot');
    src: local('MinionPro-Bold'),
        url('../fonts/minionpro/MinionPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-Bold.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-Bold.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/minionpro/MinionPro-BoldIt.eot');
    src: local('MinionPro-BoldIt'),
        url('../fonts/minionpro/MinionPro-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/minionpro/MinionPro-BoldIt.woff2') format('woff2'),
        url('../fonts/minionpro/MinionPro-BoldIt.woff') format('woff'),
        url('../fonts/minionpro/MinionPro-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}